import React from 'react';
import Title from '../components/title';
import styles from '../styles/scss/pages/useArticle.module.scss';
import SEO from "../components/seo"
import PageContents from '../components/pageContents'
import HeroSimple from '../components/heroSimple';
import CtaBanner from "../components/ctaBanner";
import Toplist from '../components/toplist';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'gatsby';
import NewLayout from '../layout/newLayout';

const useArticle = ({ pageContext }) => {

    var useArticle = pageContext.useArticle
    useArticle.seo = {
        title: useArticle.title,
        description: useArticle.teaser,
        twitterCard: null
    }
    
    return (
        <NewLayout>
            {useArticle.seo.title && <h1 style={{ display: 'none' }}>{useArticle.seo.title}</h1>}
            <SEO page={useArticle} />
            <div>
                {useArticle.heroImage &&
                    <HeroSimple image={useArticle.heroImage.url} />
                }

                <Container style={{ marginBottom: "25px", marginTop: "50px" }}>
                    <Row>
                        <Col className={styles.titleContainer}>
                            <Title value={useArticle.title} alignLeft className={styles.title} />
                            <Link to={'/uses/'} className={styles.goBackLink}>
                                {'<<'} Back
                            </Link>
                        </Col>

                    </Row>
                    {useArticle.content[0] &&
                        <Row>
                            <Col className={styles.contentContainer}>
                                <PageContents content={useArticle.content} />
                            </Col>
                        </Row>
                    }
                    {useArticle.topsList &&
                        <Row>
                            <Col>
                                <Toplist topListItems={useArticle.topsList} />
                            </Col>
                        </Row>}
                </Container>
            </div>
            <CtaBanner text="WANT TO GET MORE TIPS AND TRICKS?" buttonText="SUBSCRIBE TO THE NEWSLETTER" buttonUrl="/signup/" />
        </NewLayout>
    );
}

export default useArticle;
