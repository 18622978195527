import React from 'react';
import styles from '../styles/scss/components/toplist.module.scss';
import EventAnchor from './eventAnchor';
import Text from './text';
import { supSub } from '../services/util'


const Toplist = ({ topListItems }) => {
    return (
        <div className={styles.topListContainer}>
            <ol>
                {topListItems.map((item, index) => (
                    <li key={index}>
                        <Text value={item.title} className={styles.itemListTitle} />
                        {item.content && <div className={styles.textContainer} dangerouslySetInnerHTML={{ __html: supSub(item.content) }} />}
                        {item.inUseVideo &&
                            <EventAnchor text="WATCH IN-USE VIDEOS" noMargin condensed video={item.inUseVideo} />
                        }
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default Toplist;